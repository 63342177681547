import { useState, useEffect } from "react";

export const useURLParam = (param = "adId", defaultValue = "") => {
  const [paramValue, setParamValue] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setParamValue(
      new URLSearchParams(window.location.search).get(param) ?? defaultValue
    );
    setIsLoading(false);
  }, [param]);

  return { paramValue, isLoading };
};
