import React, { useEffect } from "react";
import { Link } from "gatsby";
import "../../components/all.sass";
import { useURLParam } from "../../hooks/useURLParam";
import { StaticImage } from "gatsby-plugin-image";

const appScheme = "neumind-app://";
const stagingAppScheme = "neumind-app-staging://";
const developmentAppScheme = "neumind-app-development://";

const appStoreLink = "https://apps.apple.com/gb/app/neumind/id6443500908";
const playStoreLink =
  "https://play.google.com/store/apps/details?id=uk.co.neumind.neumind";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * Taken from https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const AppLink = () => {
  useEffect(() => {
    const redirect = () => {
      const deepLinkPath = new URLSearchParams(window.location.search).get(
        "path"
      );
      const install = new URLSearchParams(window.location.search).get(
        "install"
      );
      const stage = new URLSearchParams(window.location.search).get("stage");
      const os = getMobileOperatingSystem();
      const storeLink =
        os === "iOS"
          ? appStoreLink
          : os === "Android"
          ? playStoreLink
          : undefined;

      switch (stage) {
        case "staging":
          window.location.replace(`${stagingAppScheme}${deepLinkPath ?? ""}`);
          return;
        case "development":
          window.location.replace(
            `${developmentAppScheme}${deepLinkPath ?? ""}`
          );
          return;
        default:
          if (install !== null && storeLink !== undefined) {
            window.location.replace(storeLink);
          } else {
            window.location.replace(`${appScheme}${deepLinkPath ?? ""}`);
          }
      }
    };

    setTimeout(redirect, 50);
  }, []);

  const { paramValue: install, isLoading } = useURLParam("install", null);

  return (
    <div>
      <meta name="robots" content="noindex" />
      <div className="hero is-small">
        {!isLoading && (
          <div className="section">
            <div className="content">
              {install === null && (
                <div className="columns">
                  <div className="column is-10 is-offset-1 has-text-centered">
                    <p className="subtitle has-text-centered">
                      Install the neumind app, create an account, and subscribe
                      to use this link.
                    </p>
                  </div>
                </div>
              )}
              {install !== null && (
                <div className="columns is-centered has-text-centered">
                  <div className="column is-12-mobile is-8-desktop">
                    <h1 className="title is-2 has-text-m3-primary pb-4 mb-0">
                      Hello!
                    </h1>
                    <div>
                      <p className="subtitle is-3-tablet is-4-mobile">
                        Please install neumind using the links below.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="columns is-10 is-centered has-text-centered is-mobile">
                <div className="column is-5">
                  <Link to={appStoreLink} title="App Store link">
                    <StaticImage
                      src="../../../static/img/app-store.png"
                      alt="neumind iOS"
                      height={50}
                    />
                  </Link>
                </div>
                <div className="column is-5">
                  <Link to={playStoreLink} title="Play Store link">
                    <StaticImage
                      src="../../../static/img/play-store.png"
                      alt="neumind Android"
                      height={50}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLink;
